import * as React from "react";
import Layout from "../components/layout";
import "./index.css";

const ContactPage = () => {
  return (
    <Layout hasLongInner>
      <h3>Privacy</h3>

      <p>
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit or make a purchase from
        https://rckive.com (the "Site") or the RCKIVE app (the "App").
      </p>
      <h3>PERSONAL INFORMATION WE COLLECT</h3>
      <p>
        When you open the App, we automatically collect certain information
        about your device, including information about your device make, IP
        address, and time zone. Additionally, as you browse the App, we collect
        information about the individual pages or products that you view, and
        information about how you interact with the App. We refer to this
        automatically-collected information as “Device Information.”
      </p>
      <p>We collect Device Information using the following technologies:</p>
      <ul>
        <li>
          “Log files” track actions occurring on the App, and collect data
          including your IP address, device type, Internet service provider,
          referring/exit pages, and date/time stamps.
        </li>
        <li>
          We collect information on how you use the App, like where you click
          and how long you spend on certain screens.
        </li>
        <li>
          Additionally when you make a purchase or attempt to make a purchase
          through the App, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          (including credit card numbers and iDEAL information), email address,
          and phone number. We refer to this information as “Order Information.”
        </li>
      </ul>
      <p>
        We also collect information needed to place your order. This includes
        the photos you select in the App, including their metadata like location
        and creation date, and any captions and label configurations you add to
        your box.
      </p>
      <p>
        When we talk about “Personal Information” in this Privacy Policy, we are
        talking both about Device Information and Order Information.
      </p>
      <p>
        <h3>HOW DO WE USE YOUR PERSONAL INFORMATION?</h3>
      </p>
      <p>
        We use the Order Information that we collect generally to fulfill any
        orders placed through the App (including processing your payment
        information, arranging for shipping, and providing you with invoices
        and/or order confirmations). Additionally, we use this Order Information
        to: Communicate with you; Screen our orders for potential risk or fraud;
        and When in line with the preferences you have shared with us, provide
        you with information or advertising relating to our products or
        services. We use the Device Information that we collect to help us
        screen for potential risk and fraud (in particular, your IP address),
        and more generally to improve and optimize our App (for example, by
        generating analytics about how our customers browse and interact with
        the App, and to assess the success of our marketing and advertising
        campaigns).
      </p>
      <h3>SHARING YOUR PERSONAL INFORMATION</h3>
      <p>
        We share your Personal Information with third parties to help us use
        your Personal Information, as described above. For example, we use
        Shopify to power our online store--you can read more about how Shopify
        uses your Personal Information here:
        https://www.shopify.com/legal/privacy.
      </p>
      <p>
        Finally, we may also share your Personal Information to comply with
        applicable laws and regulations, to respond to a subpoena, search
        warrant or other lawful request for information we receive, or to
        otherwise protect our rights.
      </p>
      <h3>BEHAVIOURAL ADVERTISING</h3>
      <p>
        As described above, we use your Personal Information to provide you with
        targeted advertisements or marketing communications we believe may be of
        interest to you. For more information about how targeted advertising
        works, you can visit the Network Advertising Initiative's (“NAI”)
        educational page at
        http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
      </p>
      <p>
        You can opt out of targeted advertising by:
        https://www.google.com/settings/ads/anonymous. Additionally, you can opt
        out of some of these services by visiting the Digital Advertising
        Alliance's opt-out portal at: http://optout.aboutads.info/.
      </p>
      <h3>YOUR RIGHTS</h3>
      <p>
        If you are a European resident, you have the right to access personal
        information we hold about you and to ask that your personal information
        be corrected, updated, or deleted. If you would like to exercise this
        right, please contact us through the contact information below.
        Additionally, if you are a European resident we note that we are
        processing your information in order to fulfill contracts we might have
        with you (for example if you make an order through the App), or
        otherwise to pursue our legitimate business interests listed above.
        Additionally, please note that your information will be transferred
        outside of Europe, including to Canada and the United States.
      </p>
      <h3>DATA RETENTION</h3>
      <p>
        When you place an order through the App, we will maintain your Order
        Information for our records unless and until you ask us to delete this
        information.
      </p>
      <h3>MINORS</h3>
      <p>The App is not intended for individuals under the age of 13.</p>
      <h3>CHANGES</h3>
      <p>
        We may update this privacy policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal or
        regulatory reasons.
      </p>
      <h3>CONTACT US</h3>
      <p>
        For more information about our privacy practices, if you have questions,
        or if you would like to make a complaint, please contact us{" "}
        <a href="https://instagram.com/rckive_official/">on Instagram</a> or by
        mail using the details provided below:
      </p>
      <p>
        Willem van Weldammelaan 133, Amsterdam, Noord-Holland, 1082KT,
        Netherlands
      </p>
    </Layout>
  );
};

export default ContactPage;
